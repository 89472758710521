import React from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface MonthSelectorProps {
  selectedMonths: string[];
  setSelectedMonths: React.Dispatch<React.SetStateAction<string[]>>;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({
  selectedMonths,
  setSelectedMonths,
}) => {
  const handleSelect = (month: string) => {
    setSelectedMonths((prev) =>
      prev.includes(month) ? prev.filter((m) => m !== month) : [...prev, month]
    );
  };

  return (
    <div>
      <label className="block mb-2 text-gray-700">Select Months</label>
      <div className="grid grid-cols-3 gap-2">
        {months.map((month) => (
          <button
            key={month}
            type="button"
            onClick={() => handleSelect(month)}
            className={`py-2 px-4 rounded-lg border border-gray-300 ${
              selectedMonths.includes(month)
                ? "bg-blue-500 text-white"
                : "bg-white text-gray-700"
            }`}
          >
            {month}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MonthSelector;
