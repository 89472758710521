import React, { useRef } from "react";
import { toPng } from "html-to-image";
import AWS from "aws-sdk";

interface ReceiptProps {
  name: string;
  selectedMonths: string[];
  feePerMonth: number;
}

const Receipt: React.FC<ReceiptProps> = ({
  name,
  selectedMonths,
  feePerMonth,
}) => {
  const receiptRef = useRef<HTMLDivElement>(null);

  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  if (!name || selectedMonths.length === 0) return null;

  const totalFee = selectedMonths.length * feePerMonth;
  const receiptNumber = new Date()
    .toISOString()
    .replace(/[-:.]/g, "")
    .slice(0, 15);

  const generateUniqueFileName = (name: string) => {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const sanitizedFileName = name.replace(/\s+/g, "_").toLowerCase();
    return `${sanitizedFileName}-${timestamp}.png`;
  };

  const uploadToS3 = async (imageBlob: Blob, fileName: string) => {
    const bucketName = process.env.REACT_APP_S3_BUCKET_NAME;

    if (!bucketName) {
      throw new Error(
        "S3 bucket name is undefined. Please set REACT_APP_S3_BUCKET_NAME in your .env file."
      );
    }

    const params = {
      Bucket: bucketName,
      Key: `/${fileName}`,
      Body: imageBlob,
      ContentType: "image/png",
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Key;
    } catch (err) {
      console.error("Error uploading to S3:", err);
      return null;
    }
  };

  const generateImageAndUpload = async () => {
    if (receiptRef.current === null) {
      return;
    }

    try {
      const dataUrl = await toPng(receiptRef.current);
      const imageBlob = await fetch(dataUrl).then((r) => r.blob());
      const fileName = generateUniqueFileName(name);

      const filePath = await uploadToS3(imageBlob, fileName);

      if (filePath) {
        const customUrl = `https://receipts.vismayaclubmongam.online/assets/receipts${filePath}`;

        const whatsappMessage = encodeURIComponent(
          `Thank you ${name} for paying Vismaya Club Varisangya for the month(s) of ${selectedMonths.join(
            ", "
          )}.
          
Here is your receipt: ${customUrl}`
        );

        const whatsappUrl = `https://wa.me/?text=${whatsappMessage}`;
        window.open(whatsappUrl, "_blank");
      }
    } catch (err) {
      console.error("Failed to generate and upload receipt image", err);
    }
  };

  return (
    <div className="bg-white p-4  border border-gray-300 mt-4 text-gray-800">
      <div ref={receiptRef} className="w-full max-w-sm p-4 mx-auto">
        {/* Club Logo */}
        <div className="text-center mb-4">
          <svg
            className="mx-auto mb-2 w-24 h-24"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="302.000000pt"
            height="450.000000pt"
            viewBox="0 0 302.000000 450.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,450.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M1472 3563 c-5 -10 -17 -43 -26 -74 -21 -76 -82 -155 -204 -269 -109
-101 -124 -126 -69 -117 54 8 91 -15 146 -93 28 -40 59 -95 70 -124 20 -54 51
-191 71 -314 l12 -73 66 3 65 3 41 100 c23 55 72 158 110 228 71 136 226 386
250 407 27 22 50 8 60 -38 6 -23 15 -42 22 -42 42 0 52 95 14 136 -28 28 -92
33 -127 9 -55 -39 -268 -393 -377 -629 -50 -109 -62 -103 -96 49 -15 66 -34
145 -43 175 -19 65 -78 160 -131 209 -20 19 -36 39 -36 43 0 4 96 8 213 8 281
0 282 1 154 144 -105 116 -112 126 -133 209 -17 68 -34 84 -52 50z"
              />
              <path
                d="M1306 2113 c-87 -202 -161 -374 -163 -380 -4 -10 12 -13 59 -13 l65
0 43 100 43 100 182 0 182 0 46 -100 46 -100 55 0 c31 0 56 4 56 9 0 5 -69
175 -154 378 l-155 368 -73 3 -74 3 -158 -368z m325 0 l28 -63 -119 0 c-66 0
-120 3 -120 6 0 4 26 65 58 136 l57 129 34 -73 c19 -40 47 -101 62 -135z"
              />
              <path
                d="M939 1638 c-1 -18 -1 -82 -1 -143 1 -60 1 -131 2 -157 l0 -48 539 0
c605 0 549 8 542 -77 l-3 -43 -539 0 -539 0 0 -65 0 -65 608 2 607 3 0 185 0
185 -542 3 -543 2 0 65 0 65 545 0 545 0 0 60 0 60 -610 0 -610 0 -1 -32z"
              />
              <path
                d="M730 800 l0 -190 815 0 815 0 0 65 0 65 -755 0 -755 0 0 60 0 60 755
0 755 0 0 65 0 65 -815 0 -815 0 0 -190z"
              />
            </g>
          </svg>
          <h2 className="text-xl font-extrabold inconsolata-font">
            VISMAYA ARTS & SPORTS CLUB
          </h2>
          <p className="text-gray-700 inconsolata-font">Mongam</p>
        </div>

        {/* Receipt Details */}
        <p className="text-center text-m font-bold text-gray-500 inconsolata-font">
          {new Date().toLocaleString()}
        </p>
        <div className="my-4 border-dashed border border-black p-2 text-center">
          <h3 className="text-m font-bold inconsolata-font">Receipt No.</h3>
          <p className="font-mono text-lg inconsolata-font">{receiptNumber}</p>
        </div>

        {/* Member and Payment Details */}
        <div className="mb-4 border-solid border-b pb-4 border-black border-dashed">
          <p className="flex justify-between">
            <span className="font-semibold inconsolata-font">Member Name:</span>
            <span className="inconsolata-font">{name}</span>
          </p>
          <p className="flex justify-between">
            <span className="font-semibold inconsolata-font">
              Month(s) Paid:
            </span>
            <span className="inconsolata-font">
              {selectedMonths.join(", ")}
            </span>
          </p>
        </div>

        {/* Fee Calculation */}
        <div className="mb-4 border-solid border-b pb-4 border-black border-dashed">
          <p className="flex justify-between">
            <span className="font-semibold inconsolata-font">Amount:</span>
            <span className="inconsolata-font">Rs. {feePerMonth} / Month</span>
          </p>
          <p className="flex justify-between">
            <span className="font-semibold inconsolata-font">Total:</span>
            <span className="inconsolata-font">Rs. {totalFee}</span>
          </p>
        </div>

        {/* Footer Message */}
        <p className="text-center font-bold text-lg mt-4 inconsolata-font ">
          Thank you!!!
        </p>
      </div>

      <button
        onClick={generateImageAndUpload}
        className="mt-4 w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
      >
        Share Receipt
      </button>
    </div>
  );
};

export default Receipt;
