import React, { useState } from "react";
import MonthSelector from "./MonthSelector";
import Receipt from "./Receipt";

const ReceiptGenerator: React.FC = () => {
  const [name, setName] = useState("");
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const feePerMonth = 100;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Receipt will be generated after form submit
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <h1 className="text-xl font-bold mb-4 text-center">
          Subscription Receipt
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2 text-gray-700">Member Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
              placeholder="Enter Member Name"
              required
            />
          </div>
          <div className="mb-4">
            <MonthSelector
              selectedMonths={selectedMonths}
              setSelectedMonths={setSelectedMonths}
            />
          </div>
        </form>
        <div className="mt-6">
          <Receipt
            name={name}
            selectedMonths={selectedMonths}
            feePerMonth={feePerMonth}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptGenerator;
